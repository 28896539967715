import React from 'react';
import { AppBar, Toolbar, Typography, Box } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Custom theme for the header component
const theme = createTheme({
  palette: {
    primary: {
      main: '#3B80DF', // Primary color for the header background
    },
    secondary: {
      main: '#BAD1CE', // Secondary color for accenting
    },
  },
  typography: {
    h6: {
      fontWeight: 600,
      color: '#ffffff', // White text for the logo text
    },
  },
});

const Header = () => {
  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static" color="primary">
        <Toolbar>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img
              src="/logo.png"
              alt="Smart Homes Logo"
              style={{ width: '40px', marginRight: '10px' }}
            />
            <Typography variant="h6" component="div">
              Smart Homes
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
};

export default Header;
