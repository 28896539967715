import React, { useState } from "react";
import axios from "axios";
import {
  Container,
  Grid,
  TextField,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  ThemeProvider,
  createTheme,
  CssBaseline,
  Modal,
  Box,
  Divider,
} from "@mui/material";
import { Delete } from "@mui/icons-material";

import Header from "./header.js";
import "./app.css";

// Define custom theme using the color scheme
const theme = createTheme({
  palette: {
    primary: {
      main: "#3B80DF", // Blue
    },
    secondary: {
      main: "#BAD1CE", // Light blue/green
    },
    background: {
      default: "#ffffff", // Dark background
      paper: "#BAD1CE", // Light background for cards
    },
    text: {
      primary: "#2A2B2E", // White text for dark backgrounds
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h4: {
      fontWeight: 600,
      color: "#BAD1CE",
    },
    h6: {
      color: "#BAD1CE",
    },
    body1: {
      color: "#2A2B2E",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          textTransform: "none",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffffff",
          borderRadius: "8px",
        },
      },
    },
  },
});

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.default",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};

function App() {
  const [token, setToken] = useState("");
  const [devices, setDevices] = useState([]);
  const [keys, setKeys] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [selectedDeviceName, setSelectedDeviceName] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  const [newKey, setNewKey] = useState("");
  const [validFrom, setValidFrom] = useState("");
  const [validTo, setValidTo] = useState("");

  const serverUrl = ``;
  //const serverUrl = `http://${window.location.hostname}:8888`;

  const login = () => {
    axios
      .post(`${serverUrl}/login`, { username, password })
      .then((res) => {
        setToken(res.data.token);
      })
      .catch((err) => {
        alert("Login failed");
      });
  };

  /*React.useEffect(() => {
    if (token) {
      getDevices();
    }
  });*/

  const register = () => {
    axios
      .post(`${serverUrl}/register`, { username, password, email })
      .then((res) => {
        alert("Registration successful, you can now log in.");
      })
      .catch((err) => {
        alert("Registration failed");
      });
  };

  const getDevices = () => {
    axios
      .get(`${serverUrl}/devices`, { headers: { Authorization: token } })
      .then((res) => {
        setDevices(res?.data?.devices);
      })
      .catch((err) => {
        console.log(err);
        alert("Failed to load devices");
      });
  };

  const getKeys = (deviceId) => {
    axios
      .get(`${serverUrl}/device/${deviceId}/keys`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        setSelectedDevice(deviceId);
        setKeys(res.data.keys);
        setOpenModal(true);
      })
      .catch((err) => {
        alert("Failed to load keys");
      });
  };

  const deleteKey = (deviceId, keyId) => {
    axios
      .delete(`${serverUrl}/device/${deviceId}/keys/${keyId}`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        alert("Key deleted successfully");
        getKeys(deviceId);
      })
      .catch((err) => {
        alert("Failed to delete key");
      });
  };

  const addKey = (deviceId) => {
    axios
      .post(
        `${serverUrl}/device/${deviceId}/keys`,
        { passkey: newKey, valid_from: validFrom, valid_to: validTo },
        {
          headers: { Authorization: token },
        }
      )
      .then((res) => {
        alert("Key added successfully");
        setNewKey("");
        getKeys(deviceId);
      })
      .catch((err) => {
        alert("Failed to add key");
      });
  };

  const unlockDoor = (deviceId) => {
    axios
      .post(
        `${serverUrl}/device/${deviceId}/unlock`,
        { },
        {
          headers: { Authorization: token },
        }
      )
      .then((res) => {
        alert("Unlocked successfully");
      })
      .catch((err) => {
        alert("Failed to unlock");
      });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setKeys([]); // Clear keys when modal closes
  };

  const displayDevice = (device) => {
    setSelectedDeviceName(device.device_name);
    getKeys(device.id);
  };

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <CssBaseline />
      <Container>
        <Typography variant="h4" gutterBottom align="center">
          Smart Lock Management
        </Typography>

        {!token && (
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h6">Login</Typography>
              <TextField
                label="Username"
                fullWidth
                variant="outlined"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                margin="normal"
              />
              <TextField
                label="Password"
                type="password"
                fullWidth
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                margin="normal"
              />
              <Button
                variant="contained"
                color="primary"
                onClick={login}
                fullWidth
              >
                Login
              </Button>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="h6">Register</Typography>
              <TextField
                label="Name Surname"
                fullWidth
                variant="outlined"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                margin="normal"
              />
              <TextField
                label="Password"
                type="password"
                fullWidth
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                margin="normal"
              />
              <TextField
                label="Email"
                type="email"
                fullWidth
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                margin="normal"
              />
              <Button
                variant="contained"
                color="secondary"
                onClick={register}
                fullWidth
              >
                Register
              </Button>
            </Grid>
          </Grid>
        )}

        {token && (
          <Grid container spacing={2} className="mt-5">
            <Grid item xs={12}>
              <Typography variant="h6">Your Devices</Typography>
              <Button variant="outlined" color="info" onClick={getDevices}>
                Load Devices
              </Button>
              <List>
                {devices.map((device) => (
                  <React.Fragment>
                    <ListItem
                      key={device.id}
                      button
                      onClick={() => displayDevice(device)}
                    >
                      <ListItemText primary={device.device_name} />
                    </ListItem>
                    <Divider variant="fullWidth" component="li" />
                  </React.Fragment>
                ))}
              </List>
            </Grid>
          </Grid>
        )}

        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="keys-modal-title"
          aria-describedby="keys-modal-description"
        >
          <Box sx={modalStyle}>
            <Typography id="keys-modal-title" variant="h6" component="h1">
              {selectedDeviceName}
            </Typography>
            <Button variant="outlined" onClick={() => unlockDoor(selectedDevice)}>Unlock</Button>
            <Divider sx={{ my: 2 }} />
            <Typography variant="h6" gutterBottom>
              Keys
            </Typography>
            <List>
              {keys.map((key) => (
                <ListItem
                  key={key.id}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      onClick={() => deleteKey(selectedDevice, key.id)}
                    >
                      <Delete />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary={`Passkey: ${key.passkey}`}
                    secondary={`Valid: ${
                      key.valid_from
                        ? new Date(key.valid_from).toLocaleString()
                        : "N/A"
                    } — ${
                      key.valid_to
                        ? new Date(key.valid_to).toLocaleString()
                        : "N/A"
                    }`}
                  />
                </ListItem>
              ))}
              {keys.length === 0 && <p>No keys stored.</p>}
            </List>

            <Typography variant="h6" gutterBottom>
              Add New Key
            </Typography>
            <TextField
              label="New Key"
              fullWidth
              variant="outlined"
              value={newKey}
              onChange={(e) => setNewKey(e.target.value)}
              margin="normal"
            />
            <TextField
              label="Valid From"
              type="datetime-local"
              fullWidth
              variant="outlined"
              value={validFrom}
              onChange={(e) => setValidFrom(e.target.value)}
              margin="normal"
            />
            <TextField
              label="Valid To"
              type="datetime-local"
              fullWidth
              variant="outlined"
              value={validTo}
              onChange={(e) => setValidTo(e.target.value)}
              margin="normal"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => addKey(selectedDevice)}
              fullWidth
            >
              Add Key
            </Button>
          </Box>
        </Modal>

        <footer>&copy; 2024 Software Heroes, s.r.o.</footer>
      </Container>
    </ThemeProvider>
  );
}

export default App;
